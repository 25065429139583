<template>
  <div class="height_search_box">
    <div class="row_content row">
      <div class="breadcrumb_box mb20 fs14">
        >> <span class=" hand" @click="goToHome">首页</span>
        - 高级搜索
      </div>
      <div class="display_flex">
        <div class="search_list_box my_card">
          <div class="fs16 mb20 search_type_title">构建检索式: </div>
          <div class="search_item mb20" v-for="(item, i) in selectAdvanceSearchList" :key="i">
            <!-- 搜索类型 -->
            <div class="searce_type_box">
              <el-select size="mini" v-model="item.enName" @change="onSearchType(item)"
                         placeholder="请选择类型">
                <el-option v-for="searchType in advanceSearchList" :key="searchType.id"
                           :label="searchType.name" :value="searchType.enName">
                </el-option>
              </el-select>
            </div>
            <!-- 搜索值 -->
            <div class="searce_val_box">
              <el-input size="mini" :readonly="!item.enName" v-if="item.type === 'text'"
                        :placeholder="!item.enName?'请先选择类型再输入内容':'请输入内容'" v-model="item.inputVal"
                        clearable>
              </el-input>
              <!-- type="date" format="yyyy年MM月"-->
              <el-date-picker size="mini" v-if="item.type === 'date'" v-model="item.time"
                              type="daterange" range-separator="至" start-placeholder="开始日期"
                              end-placeholder="结束日期" value-format="yyyy-MM-dd" unlink-panels
                              :clearable="false" @change="handleTime(item)"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </div>
            <!-- 关系 -->
            <div class="relation_box">
              <el-select v-if="i!==0" size="mini" placeholder="and" v-model="item.option">
                <el-option v-for="option in options" :key="option.value" :label="option.label"
                           :value="option.value">
                </el-option>
              </el-select>
            </div>
            <!-- 操作按钮 -->
            <div class="action_btn_box">
              <i @click="onDeleteSerachItem(item)"
                 class="el-icon-remove-outline delete_btn fs20  hand" v-if="i!==0"></i>
              <i @click="onAddSerachItem" :class="['el-icon-circle-plus','add_btn',' hand',{
              'is_disabled':selectAdvanceSearchList.length===10
            }]" v-if="i===selectAdvanceSearchList.length -1"></i>
            </div>
          </div>

          <!-- 条件展示 -->
          <div class="fs16  mb20 search_type_title">查询条件</div>
          <div class="advanceSearchVal mb20">
            <div class="advanceSearch_item_box" v-for="(item,i) in FilterSelectAdvanceSearchList"
                 :key="i">
              <div class="advanceSearch_item" v-show="item.inputVal || item.newTime">
                <span class="option" v-show="i!==0">{{item.option}}</span>
                <span class="key">[ {{item.name}} ]</span>
                <span class="val">
                  <template v-if="item.type === 'text'">
                    {{item.inputVal}}
                  </template>
                  <template v-if="item.type === 'date'">
                    {{item.time.join('至')}}
                  </template>
                </span>
              </div>
            </div>
          </div>
          <!-- 搜索按钮盒子 -->
          <div class="advanceSearch_btn_box">
            <span class="advanceSearch_btn  hand" @click="onGoAdvanceSearchDocList">搜索</span>
            <span class=" hand reset_btn" @click="onReset">清空</span>
          </div>
        </div>

        <!-- 筛选 -->
        <div class="search_filter_box my_card">
          <SearchFilter :isShowTime="isShowTime" :filterConfig="filterConfig" />
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { gainHighField } from '@/api/article.js'
import { mapGetters, mapMutations } from 'vuex'
import SearchFilter from '@/components/searchFilter/index.vue'
import { setFilterSearchVal, removeFilterSearchVal, setAdvancrSearch, removeAdvancrSearch } from '@/utils/storage.js'
import store from '@/store/index.js'
import { endTime } from '@/utils/index.js'
export default {
  components: {
    SearchFilter
  },
  data () {
    return {
      pickerOptions: {
        disabledDate: (timeval) => {
          return timeval > Date.now()
        },
        shortcuts: [
          {
            text: '去年',
            onClick (picker) {
              const year = (new Date().getFullYear() - 1).toString()
              const start = new Date(year)
              const end = new Date(year, 11, 31)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '今年至今',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近六个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 5)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      advanceSearchList: [], // 筛选条件集合
      options: [
        { value: 'AND', label: 'AND' },
        { value: 'OR', label: 'OR' },
        { value: 'NOT', label: 'NOT' }
      ],
      // 筛选集合
      filterConfig: {
        time: [1920, endTime],
        category: [],
        if: [],
        docPublishType: [],
        type: [],
        sex: [],
        age: []
      },
      isShowTime: true
    }
  },
  methods: {
    ...mapMutations('heightSearch', ['addAdvanceSearchItem', 'initSelectAdvanceSearchList', 'removeAdvanceSearchItem', 'addAdvanceSearchItem']),
    resetPageConfig () {
      this.filterConfig = this.$options.data().filterConfig
      this.initSelectAdvanceSearchList()
      removeFilterSearchVal()
      removeAdvancrSearch()
    },
    goToHome () {
      this.$router.push('/')
    },
    // 跳转高搜列表
    onGoAdvanceSearchDocList () {
      const filterAdvanceSearchList = this.selectAdvanceSearchList.filter(item => (item.inputVal || item.newTime))
      if (filterAdvanceSearchList.length === 0) return this.$message.warning('至少填写一项搜索条件')
      setFilterSearchVal(this.filterConfig)
      setAdvancrSearch(filterAdvanceSearchList)
      this.$myCount('PC-搜索文献')
      this.$router.push('/advanceSearchDocList')
    },
    // 重置
    onReset () {
      this.initSelectAdvanceSearchList()
      this.isShowTime = true
    },
    // 添加一项
    onAddSerachItem () {
      if (this.selectAdvanceSearchList.length === 10) return
      this.addAdvanceSearchItem()
    },
    // 移除某一项
    onDeleteSerachItem (row) {
      this.removeAdvanceSearchItem(row)
      const dataItem = this.selectAdvanceSearchList.find(item => item.type === 'date')
      this.isShowTime = !dataItem
      if (!this.isShowTime) this.filterConfig.time = this.$options.data().filterConfig.time
    },
    // 处理后端需要的时间格式
    handleTime (item) {
      item.newTime = '$$' + item.time.join('$$')
    },
    // 搜索类型下拉框改变
    onSearchType (selectItem) {
      const serachType = this.advanceSearchList.find((item) => item.enName === selectItem.enName)
      if (serachType) {
        const key = ['type', 'name']
        key.forEach(key => {
          selectItem[key] = serachType[key]
        })
        selectItem.inputVal = ''
        selectItem.time = []
      }
      const dataItem = this.selectAdvanceSearchList.find(item => item.type === 'date')
      this.isShowTime = !dataItem
      if (!this.isShowTime) this.filterConfig.time = this.$options.data().filterConfig.time
    },
    // 获取高搜筛选字段
    async getadvanceSearchList () {
      const res = await gainHighField()
      this.advanceSearchList = [...res.data.def, ...res.data.notDef]
    }
  },
  computed: {
    ...mapGetters(['selectAdvanceSearchList']),
    FilterSelectAdvanceSearchList () {
      return this.selectAdvanceSearchList.filter(item => item.inputVal || item.newTime)
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/advanceSearchDocList') {
      store.commit('heightSearch/sessionStorageAdvanceSearch')
    } else {
      store.commit('heightSearch/initSelectAdvanceSearchList')
    }
    next()
  },
  created () {
    this.getadvanceSearchList()
    this.filterConfig = this.$options.data().filterConfig
  },
  beforeRouteLeave (to, from, next) {
    if (to.path !== '/advanceSearchDocList') {
      this.resetPageConfig()
    }
    next()
  }
}
</script>
<style lang="less" scoped>
.height_search_box {
  width: 100%;
  min-height: 400px;
  background-color: #f5f7fb;
  .row_content {
    padding: 20px 0;
    min-height: calc(100vh - 252px);
  }
  .breadcrumb_box {
    span {
      color: #4b639f;
      cursor: pointer;
    }
  }
  .search_list_box {
    flex: 1;
    padding: 20px 30px;
    margin-right: 20px;
    /deep/.el-input__inner {
      background-color: #f5f7fb;
      border: 0;
    }
    /deep/.el-range-input {
      background-color: #f5f7fb;
    }
    .search_type_title {
      padding-top: 10px;
      font-weight: 500;
      color: #4b639f;
    }
  }
  .search_item {
    width: 100%;
    display: grid;
    grid-template-columns: 180px 1fr 100px 50px;
    grid-gap: 0 20px;
    align-items: center;
    /deep/ .el-date-editor {
      width: 100%;
    }
    .action_btn_box {
      .delete_btn {
        color: #4b639f;
      }
      .add_btn {
        font-size: 22px;
        margin-left: 5px;
        color: #4b639f;
      }
      .is_disabled {
        color: #9fadd1;
        cursor: no-drop;
      }
    }
  }
  .advanceSearchVal {
    width: 100%;
    min-height: 100px;
    word-break: break-all;
    border: 1px solid #e8e8e8;
    padding: 10px;
    .advanceSearch_item_box {
      display: inline-block;
    }
    .advanceSearch_item {
      .option {
        margin: 0 5px;
        font-size: 14px;
        color: #fa6400;
      }
    }
  }
  .advanceSearch_btn_box {
    text-align: end;
    .advanceSearch_btn {
      display: inline-block;
      width: 100px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      margin-right: 20px;
      background-color: #4b639f;
      color: #fff;
    }
    .reset_btn {
      width: 50px;
    }
  }
  .search_filter_box {
    width: 288px;
    flex-shrink: 0;
  }
}
</style>
