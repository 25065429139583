<template>
  <div>
    <el-button ref="openDocTypeOptionsDialogBtn" class="hand fs14" type="text"
               @click="openDocTypeOptionsDialog">设置常用类型>>
    </el-button>
    <MyDialog ref="docTypeDialog" :isShow.sync="isDosTypeOptions" :position="position" width="640px"
              height="500px">
      <template #titleContent>
        <div class="title_content">
          <span class="title fs14">
            文献类型
          </span>
          <span class="warning fs12">
            选择您常用的文献类型，将展示在筛选条件里
          </span>
        </div>
      </template>
      <div class="content">
        <div class="content_top">
          <div class="top_left">
            <span class=top_text>
              当前已选择类型数量:
            </span>
            <span class="select_total">{{selectDocTypes.length || 0}}</span>
          </div>
          <div class="top_right hand" @click="submitSelectDocType('reSet')">恢复默认设置</div>
        </div>
        <div class="docType_list">
          <el-checkbox-group v-model="selectDocTypes">
            <el-checkbox v-for="item in docTypeAll" :key="item.id" :label="item.id">
              <el-tooltip v-if="item.typeCn.length>10" class="item" effect="dark"
                          :content="item.typeCn" placement="top">
                <span> {{item.typeCn}}</span>
              </el-tooltip>
              <span v-else>{{item.typeCn}}</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="foor_btn_box">
          <el-button size="mini" @click="hideDialog">取消</el-button>
          <el-button type="primary" size="mini" @click="submitSelectDocType()">确定</el-button>
        </div>
      </div>
    </MyDialog>
  </div>

</template>
<script>
import MyDialog from '@/components/myDialog/newIndex.vue'
import { getSearchDocTypes, updataUserDocTypes } from '@/api/article'
import { mapGetters } from 'vuex'
export default {
  components: {
    MyDialog
  },
  props: {
    userDocType: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      position: {
        x: '',
        y: ''
      },

      docTypeAll: [],
      selectDocTypes: [],
      isDosTypeOptions: false
    }
  },
  methods: {
    async submitSelectDocType (type = 'update') {
      try {
        if (type === 'update' && this.selectDocTypes.lenght === 0) return this.$message.warning('至少选择一种类型')
        const res = await updataUserDocTypes({
          publishTypeIds: type === 'update' ? this.selectDocTypes : []
        })
        this.hideDialog()
        this.$emit('updateUesrDocTypes')
        if (res.code === 401) {
          this.$login()
          return this.$myMessage.warning('登录过期, 请重新登录')
        }
        this.$myMessage.success(type === 'update' ? '更新成功' : '重置成功')
      } catch (error) {
        this.$myMessage.error(error || '操作失败')
      }
    },
    async getDocTypeAll () {
      const res = await getSearchDocTypes()
      this.docTypeAll = res.data
    },
    hideDialog () {
      this.$refs.docTypeDialog.closeDialog()
    },
    openDocTypeOptionsDialog () {
      if (!this.userId) return this.$login()
      const position = this.$refs.openDocTypeOptionsDialogBtn.$el.getBoundingClientRect()
      this.position.x = position.left
      this.position.y = position.top
      this.selectDocTypes = [...this.userDocType]
      // this.$nextTick(() => {
      this.isDosTypeOptions = true
      // })
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created () {
    this.getDocTypeAll()
  }
}

</script>
<style lang="less" scoped>
::v-deep .title_box {
  height: 40px;
}
.title_content {
  color: #666;
  .title {
    font-weight: 600;
    color: #000000;
    margin-right: 10px;
  }
}
.content {
  width: 100%;
  height: calc(100% - 40px);
  padding: 0 30px;
}

.content_top {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  font-size: 12px;
  color: #4b639f;
  .top_text {
    color: #666;
  }
}
.docType_list {
  width: 100%;
  height: calc(100% - 100px);
  padding: 20px;
  border-radius: 2px;
  border: 1px solid #979797;
  overflow-y: auto;
  .el-checkbox-group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-gap: 10px;
    align-items: center;
    .el-checkbox {
      margin-right: 0;
      display: grid;
      grid-template-columns: 18px auto;
      align-items: center;
      /deep/ .el-checkbox__label {
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.foor_btn_box {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
