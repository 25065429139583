<template>
  <div class="search_filter_box">
    <ul>
      <li v-if="isShowTime">
        <div class="filter_Type_title">
          <span>时间</span>
        </div>
        <div class="quickTimeBtn_box">
          <span class="quickTimeBtn my_btn_slide"
                :class="{'isSelectQuickTime': isSelectQuickTime===item.val}"
                v-for="item in quickTimeList" :key="item.quick" @click="onSelectQuickTime(item)">
            {{item.quick}}
          </span>
        </div>
        <div class="option-time">
          <el-slider @change="onFilterConfigChange('time')" v-model="filterConfig.time" range
                     show-stops :max="new Date().getFullYear()" :min='1920'>
          </el-slider>
        </div>
      </li>
      <li>
        <div class="filter_Type_title">
          <span>全文</span>
        </div>
        <div class="select_item_content">
          <!-- <el-radio-group v-model="filterConfig.category" @change="onFilterConfigChange">
            <el-radio v-for="item in articleTypeList" :key="item.label" :label="item.value">
              {{item.label}}</el-radio>
          </el-radio-group> -->
          <el-checkbox-group v-model="filterConfig.category" @change="checkChange('category')">
            <el-checkbox v-for="item in articleTypeList" :key="item.label" :label="item.value">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </li>
      <li>
        <div class="filter_Type_title">
          <span>IF值</span>
        </div>
        <div class="select_item_content">
          <!-- <el-radio-group v-model="filterConfig.if" @change="onFilterConfigChange">
            <el-radio v-for="item in ifRadioList" :key="item.value" :label="item.value">
              {{item.label}}
            </el-radio>
          </el-radio-group> -->
          <el-checkbox-group v-model="filterConfig.if" @change="checkChange('if')">
            <el-checkbox v-for="item in ifRadioList" :key="item.value" :label="item.value">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </li>
      <li>
        <div class="filter_Type_title">
          <span>类型</span>
          <DocTypeDiaLogBtn :userDocType="docPublishTypeList.map(item=>item.id)"
                            @updateUesrDocTypes="getUserDocTypes" />

        </div>

        <div class="select_item_content" :class="{'fold_box': isFold}">
          <!-- <el-radio-group @change="onFilterConfigChange" v-model="filterConfig.docPublishType">
            <el-radio class="row_1" v-for="item in docPublishTypeList" :key="item.id"
                      :label="item.type">
              <el-tooltip v-if="item.typeCn.length>6" class="item" effect="dark"
                          :content="item.typeCn" placement="top">
                <span> {{item.typeCn}}</span>
              </el-tooltip>
              <span v-else> {{item.typeCn}}</span>
            </el-radio>
          </el-radio-group> -->

          <el-checkbox-group v-model="filterConfig.docPublishType"
                             @change="checkChange('docPublishType')">
            <el-checkbox v-for="item in docPublishTypeList" :key="item.id" :label="item.type">
              <el-tooltip v-if="item.typeCn.length>6" class="item" effect="dark"
                          :content="item.typeCn" placement="top">
                <span> {{item.typeCn}}</span>
              </el-tooltip>
              <span v-else> {{item.typeCn}}</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>

        <el-button v-if="docPublishTypeList.length>8" class="hand fs12" type="text"
                   @click="foldOrOpen">
          {{isFold?'展开更多': '收起'}}
        </el-button>
      </li>

      <li>
        <div class="filter_Type_title">
          <span>种类</span>
        </div>
        <div class="select_item_content">
          <!-- <el-radio-group v-model="filterConfig.type" @change="onFilterConfigChange">
            <el-radio v-for="item in type" :key="item.label" :label="item.value">
              {{item.label}}
            </el-radio>
          </el-radio-group> -->
          <el-checkbox-group v-model="filterConfig.type" @change="checkChange('type')">
            <el-checkbox v-for="item in type" :key="item.label" :label="item.value">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </li>

      <li>
        <div class="filter_Type_title">
          <span>性别</span>
        </div>
        <div class="select_item_content">
          <!-- <el-radio-group v-model="filterConfig.sex" @change="onFilterConfigChange">
            <el-radio v-for="item in sex" :key="item.value" :label="item.value">
              {{item.label}}
            </el-radio>
          </el-radio-group> -->
          <el-checkbox-group v-model="filterConfig.sex" @change="checkChange('sex')">
            <el-checkbox v-for="item in sex" :key="item.value" :label="item.value">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </li>

      <li>
        <div class="filter_Type_title">
          <span>年龄</span>
        </div>
        <div class="select_item_content age_filter_constnt">
          <!-- <el-radio-group v-model="filterConfig.age" @change="onFilterConfigChange">
            <el-radio v-for="(item,i) in age" :key="item.label" :label="item.value+'&&'+i">
              {{item.label}}
            </el-radio>
          </el-radio-group> -->
          <el-checkbox-group v-model="filterConfig.age" @change="checkChange('age')">
            <el-checkbox v-for="(item,i) in age" :key="item.label" :label="item.value+'&&'+i">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import DocTypeDiaLogBtn from './components/selectDocType.vue'
import { getUserDocTypes } from '@/api/article.js'
import { endTime } from '@/utils/index.js'
export default {
  components: {
    DocTypeDiaLogBtn
  },
  props: {
    // 筛选配置
    filterConfig: {
      type: Object,
      default: () => ({})
    },
    // 是否显示时间
    isShowTime: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isSelectQuickTime: null, // 选中的时间戳快捷键
      // 筛选时间快捷键列表
      quickTimeList: [
        {
          quick: '近一年',
          val: 1
        },
        {
          quick: '近五年',
          val: 5
        },
        {
          quick: '近十年',
          val: 10
        }
      ],
      // 文档类型
      articleTypeList: [
        {
          value: '0',
          label: '摘要'
        },
        {
          value: '1',
          label: '原文'
        }
      ],
      // IF值
      ifRadioList: [
        {
          value: '0$$3',
          label: '0-3'
        },
        {
          value: '3$$5',
          label: '3-5'
        }, {
          value: '5$$10',
          label: '5-10'
        },
        {
          value: '10$$20',
          label: '10-20'
        },
        {
          value: '20$$10000',
          label: '>20'
        }
      ],
      // 种类
      type: [
        {
          value: 'Humans',
          label: '人类'
        },
        {
          value: 'Animals',
          label: '动物'
        }
      ],
      // 性别
      sex: [
        {
          value: 'Female',
          label: '女性'
        },
        {
          value: 'Male',
          label: '男性'
        }
      ],
      // 年龄
      age: [
        {
          value: 'Child',
          label: '儿童(18岁以下)'
        },
        {
          value: 'Newborn',
          label: '新生儿(0-1个月)'
        },
        {
          value: 'Infant',
          label: '婴儿(0-23 个月)'
        },
        {
          value: 'Infant',
          label: '婴儿(1-23个月)'
        },
        {
          value: 'Preschool Child',
          label: '学龄前儿童(2-5岁)'
        },
        {
          value: 'Child',
          label: '儿童(6-12岁)'
        },
        {
          value: 'Adolescent',
          label: '青少年(13-18岁)'
        },
        {
          value: 'Young Adult',
          label: '青少年(19-24岁)'
        },
        {
          value: 'Adult',
          label: '成人(19岁以上)'
        },
        {
          value: 'Adult',
          label: '成人(19-44岁)'
        },
        {
          value: 'Middle Aged or Aged',
          label: '中年以上(45岁以上)'
        },
        {
          value: 'Middle Aged',
          label: '中年(45-64岁)'
        },
        {
          value: 'Aged',
          label: '年龄(65岁以上)'
        },
        {
          value: '80 and over',
          label: '年龄(80岁及以上)'
        }
      ],
      // 文献类型单选框数据
      docPublishTypeList: [],
      // 筛选key和筛选列表key映射
      filterKeyOfListKeyMap: {
        category: 'articleTypeList',
        if: 'ifRadioList',
        docPublishType: 'docPublishTypeList',
        type: 'type',
        sex: 'sex',
        age: 'age'
      },
      // 筛选类型前缀 映射
      filetTypeMap: {
        time: '@@AND$$doc_publish_time$$',
        category: '@@AND$$qiniu_url$$',
        if: '@@AND$$doc_if$$',
        docPublishType: '@@AND$$doc_publish_type$$',
        type: '@@AND$$doc_mesh_terms$$',
        sex: '@@AND$$doc_mesh_terms$$',
        age: '@@AND$$doc_mesh_terms$$'
      },
      isFold: true,
      formatSearchFilterValMap: {
        time: (typeTitle, val) => {
          const time = this.isSelectQuickTime ? this.setQuickTime(val) : val
          if (time[0] === 1920 && time[1] === endTime) return ''
          const timeStr = this.isSelectQuickTime ? time.join('$$') : `${time[0]}-01-01$$${time[1]}-12-31`
          return this.isShowTime ? typeTitle + timeStr : ''
        },
        age: (typeTitle, valArr) => {
          const newVal = valArr.reduce((res, val) => {
            res += (typeTitle + val.slice(0, val.lastIndexOf('&&')))
            return res
          }, '')
          return newVal
        }
      }
    }
  },
  methods: {
    // 重置快捷键
    resetQuickTime () {
      this.isSelectQuickTime = null
    },
    // 设置快捷时间
    setQuickTime (times) {
      const m = new Date().getMonth() + 1
      const d = new Date().getDate()
      return times.map(y => this.$initTime(`${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`))
    },
    foldOrOpen () {
      this.isFold = !this.isFold
    },
    // 获取用户自定义文献类型
    async getUserDocTypes () {
      const res = await getUserDocTypes()
      this.docPublishTypeList = res.data || []
      if (this.filterConfig.docPublishType.length > 0 && !this.docPublishTypeList.find(item => item.type === this.filterConfig.docPublishType[0])) {
        this.filterConfig.docPublishType = []
        this.onFilterConfigChange()
      }
    },
    // 时间快捷键选中
    onSelectQuickTime (item) {
      if (item.val === this.isSelectQuickTime) {
        this.isSelectQuickTime = ''
        this.filterConfig.time = [1920, endTime]
      } else {
        this.isSelectQuickTime = item.val
        const enTime = new Date().getFullYear()
        const startTime = enTime - item.val
        this.filterConfig.time = [startTime, enTime]
      }
      this.onFilterConfigChange()
    },
    // 处理搜索值
    getSearchFilterVal () {
      // console.log(this.filterConfig)
      let val = ''
      for (const key in this.filterConfig) {
        const selectValArr = this.filterConfig[key]
        const selectCheckList = this[this.filterKeyOfListKeyMap[key]] || []
        if (selectValArr.length > 0 && selectValArr.length !== selectCheckList.length) {
          const typeTitle = this.filetTypeMap[key]
          const searchVal = this.filterConfig[key]
          const formatFn = this.formatSearchFilterValMap[key]
          // val += formatFn ? formatFn(typeTitle, key === 'time' ? searchVal : searchVal[0]) : (typeTitle + searchVal)
          val += formatFn ? formatFn(typeTitle, key === 'time' ? searchVal : searchVal) : (typeTitle + searchVal.join(typeTitle))
        }
      }
      // console.log(val)
      return val
    },
    checkChange (key) {
      if (this.filterConfig[key].length > 1) {
        // this.filterConfig[key].splice(0, 1)
      }
      this.onFilterConfigChange()
    },
    // 值改变
    onFilterConfigChange (type) {
      if (type === 'time') this.isSelectQuickTime = null
      this.$emit('filterConfigChange')
    }
  },
  watch: {
    isShowTime () {
      this.resetQuickTime()
    }
  },
  created () {
    this.getUserDocTypes()
  }
}
</script>
<style lang="less" scoped>
.search_filter_box {
  width: 100%;
  padding: 20px;
  border-radius: 2px;
  background-color: #fff;
  /deep/.el-slider__button-wrapper {
    z-index: 99;
  }
  ul {
    li {
      margin-bottom: 20px;
      .filter_Type_title {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 16px;
        align-items: center;
        color: #000000;
        margin-bottom: 14px;
        flex-shrink: 0;
        user-select: none;
        .el-button {
          padding: 0;
        }
      }
      .quan {
        width: 50px;
      }
      .quickTimeBtn_box {
        display: grid;
        grid-template-columns: repeat(3, 50px);
        grid-column-gap: 20px;
        padding: 0 10px;
        .quickTimeBtn {
          height: 20px;
          line-height: 20px;
          text-align: center;
          border: 1px solid #606266;
          color: #606266;
        }
        .isSelectQuickTime {
          border-color: #4b639f;
          background-color: #4b639f;
          color: #fff;
        }
      }
      // 时间线
      .option-time {
        position: relative;
        flex: 1;
        padding: 0 15px;
        margin-bottom: 20px;
        &::after {
          position: absolute;
          left: 0px;
          bottom: -11px;
          font-size: 12px;
          content: '1920年';
          color: #8b8b8b;
        }
        &::before {
          position: absolute;
          right: 0px;
          bottom: -11px;
          font-size: 12px;
          content: '2024年';
          color: #8b8b8b;
        }
        /deep/ .el-slider__button {
          width: 14px;
          height: 14px;
        }
        /deep/.el-slider__button-wrapper {
          height: 34px;
        }
        /deep/ .el-slider__bar {
          height: 4px;
        }
        /deep/.el-slider__stop {
          background-color: #f5f7fb;
        }
      }

      // 选择项
      .select_item_content {
        height: auto;
        .el-checkbox-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 5px 10px;
          align-items: center;
          .el-checkbox {
            margin-right: 0;
            display: grid;
            grid-template-columns: 18px auto;
            align-items: center;
            /deep/ .el-checkbox__label {
              padding-left: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .age_filter_constnt {
        .el-checkbox-group {
          grid-template-columns: 1fr;
        }
      }
      .fold_box {
        height: 91px;
        overflow: hidden;
      }
    }
  }
}
</style>
